import {
  SyncedProp,
  useClearCartCookie,
  useDeepEffect,
  useEventListener,
  useFbp,
  useIsPreview,
  usePaygates,
  usePinterest,
  useRedirect,
  useSetting,
  useSnapChat,
  useSyncedState,
  useTtq,
  useVersionCookie
} from '@ui/hooks'
import { useEffect, useMemo, useState } from 'react'
import * as immutable from 'object-path-immutable'

import DropdownSelect from '@ui/components/shared/DropdownSelect'
import QuantityInput from '@ui/components/shared/QuantityInput'
import RadioButton from '@ui/components/shared/RadioButton'
import RandomNumber from '@ui/components/shared/RandomNumber'
import css from 'styled-jsx/css'
import { countItems, getCurrency, iconPath, isEmpty } from '@ui/helpers'
import { logger } from '@ui/analytics'
import { useNotiContext } from '../shared/Notification'
import { useRouter } from 'next/router'
import {
  genSid,
  isMaxItemNegative,
  isMaxQuantityDiscountProduct,
  preCalcOrder,
  showValueCD
} from '@libs/client/helpers'
import {
  useGlobalState,
  useLocalIsPuchase,
  useLocalOrder,
  useLocalVisitTime,
  useOrderService,
  usePriceChangedItems,
  useTrackingService
} from '@libs/client'
import CartModal from './cart/CartModal'
import Description from '../shared/Description'
import { useGtag } from '@ui/hooks/useGtag'
import { page_variants_item, page_variants_items } from '@libs/common'
import getLogRocket from '@ui/analytics/logRocket'
import { useCookieState } from '@ui/hooks/useCookieState'
import getConfig from 'next/config'

import Countdown from '@ui/components/shared/Countdown'
// import dynamic from 'next/dynamic'
import { CountdownNotifyModal } from '../shared/Modal'
import Policy from './Policy'
import { setCookie } from '@ui/helpers/getCookie'
import PreOrderItem from './PreOrderItem'
import PreOrderSlotLeft from './PreOrderSlotLeft'

// const Countdown = dynamic(() => import('@ui/components/shared/Countdown'), { ssr: false })

const resetCountDown = +(getConfig().publicRuntimeConfig.resetCountDown || '')

const versionBase144 = [
  'v144',
  'i144',
  'vlz1',
  'vpl1',
  'e144',
  'b144',
  'a144',
  'a145',
  'v146',
  'ba10',
  'ba11',
  'ba23',
  'bb23',
  'br1',
  'c144',
  'p144',
  'd144',
  'o144',
  'l144',
  'vpo1',
  'g144',
  'r144',
  'm144',
  'usp1',
  'usp2',
  'usp3',
  'usp4',
  'vcdn3',
  'acdn3',
  'gp01a',
  'gp01b',
  'gp02',
  'x144', //base new
  'z144', //base new
  'k144', //base new
  'f144', //base new
  'vap1', // video autoplay
  'vna2', // non argo io
  'vli1' // loading variants image
]
const versionBase144Not146 = [
  'v144',
  'i144',
  'vlz1',
  'vpl1',
  'e144',
  'b144',
  'a144',
  'a145',
  'ba10',
  'ba11',
  'ba23',
  'bb23',
  'br1',
  'c144',
  'p144',
  'd144',
  'o144',
  'l144',
  'vpo1',
  'g144',
  'r144',
  'm144',
  'usp1',
  'usp2',
  'usp3',
  'usp4',
  'vcdn3',
  'acdn3',
  'gp01b',
  'gp01a',
  'gp02',
  'x144', //base new
  'z144', //base new
  'k144', //base new
  'f144', //base new
  'vap1', //base new
  'vna2', // non argo io
  'vli1' // loading variants image
]

type ProductFormProps = {
  activeImageSyncedProp: SyncedProp<number>
  changedVariantImageSyncedProp: SyncedProp<number>
  isPayingWithPaypalSyncedProp?: SyncedProp<boolean>
}

interface ISelectVariantOptions {
  changeImage?: boolean
  redirect?: boolean
}

function initProperties(properties: S3Types.page_variants_properties) {
  const init: S3Types.item_properties = {}
  Object.keys(properties).forEach((key) => {
    init[key] = Object.entries(properties[key]?.values ?? {})?.[0]?.[1]?.code ?? ''
  })
  return init
}

function compareProperties(props1: S3Types.item_properties, props2: S3Types.item_properties) {
  let isEqual = true

  Object.keys(props1).forEach((key) => {
    if (props1[key] !== props2[key]) {
      isEqual = false
    }
  })

  return isEqual
}

function findVariant(
  properties: S3Types.item_properties,
  variantItems: S3Types.page_variants_items
) {
  const variantIds = Object.keys(variantItems)
  const foundVariantId = variantIds.find((variantId) =>
    compareProperties(properties, variantItems[variantId].properties ?? {})
  )
  return foundVariantId ? variantItems[foundVariantId] : undefined
}

export default function ProductForm({
  activeImageSyncedProp,
  changedVariantImageSyncedProp
}: ProductFormProps) {
  const router = useRouter()
  const [sellpageSetting, setStore] = useSetting('sellpage')
  const [storeSetting] = useSetting('store')
  const isPreview = useIsPreview()
  const paygates = usePaygates()
  const paypalEx = paygates?.paypalex
  const creditCard = paygates?.creditcard
  const { fbpTrackViewContent, fbpTrackAddToCart, fbpTrackInitiateCheckout } = useFbp()
  const { pinterestTrackViewContent, pinterestTrackAddToCart } = usePinterest()
  const {
    snapchatTrackViewContent,
    snapchatTrackAddToCart,
    snapchatTrackInitiateCheckout
  } = useSnapChat()
  const {
    ttqTrackViewContent,
    ttqTrackAddToCart,
    ttqTrackInitiateCheckout
    // ttqTrackCheckout
  } = useTtq()
  const { gtagTrackViewItem, gtagTrackAddToCart, gtagTrackBeginCheckout } = useGtag()
  const redirect = useRedirect()
  const images = sellpageSetting?.gallery
  const configurations = sellpageSetting?.settings?.configurations
  const socialProof = sellpageSetting?.settings?.social_proof
  const variantItems = sellpageSetting?.variants?.items ?? {}
  const properties = sellpageSetting?.variants?.properties ?? {}
  const shortDescription = sellpageSetting?.parts?.content?.short
  const [localIsPuchase] = useLocalIsPuchase()
  const [localOrder, setLocalOrder] = useLocalOrder()
  const variantItemsData = localOrder?.items ?? []
  const [totalItems, setTotalItems] = useState<number>(countItems(variantItemsData))
  const [maxQuantityDiscount] = useGlobalState<number>(isMaxQuantityDiscountProduct)
  const { trackingAddToCart, trackingViewItem } = useTrackingService()
  // const isPaypalCredit = false
  const initiateDiscountPercentUpsell =
    sellpageSetting?.settings?.general?.upsell_info?.initiate_discount_percent || 0
  const [, setSyncedActiveImage] = activeImageSyncedProp
  const [, setSyncedChangedVariantImage] = changedVariantImageSyncedProp

  const [quantity, , getQuantitySyncedProp] = useSyncedState<number>(1)
  const [selectedVariant, setSelectedVariant] = useState<S3Types.page_variants_item | null>(
    initVariant(sellpageSetting?.variants, initProperties(properties), variantItems)
  )
  const countdownText = sellpageSetting?.settings?.social_proof?.countdown_text
  const [isSellable, setIsSellable] = useState<boolean>(selectedVariant?.sellable ?? true)
  const [isVariantNotFound, setIsVariantNotFound] = useState<boolean>(false)
  const [isAddingToCart, setIsAddingToCart] = useState<boolean>(false)
  const [isBuyingNow, setIsBuyingNow] = useState<boolean>(false)
  const [, setIsCheckingOut, getIsCheckingOutSyncedProp] = useSyncedState<boolean>(false)
  const [, setCartModalActive, getCartModalActiveSyncedProp] = useSyncedState<boolean>(false)
  const hasAvailableVariants = useMemo(
    () => Object.entries(variantItems ?? {}).some(([, variant]) => variant.sellable ?? true),
    [variantItems]
  )
  const [isMaxNegative] = useGlobalState<boolean>(isMaxItemNegative)
  const [isPause, setIsPause] = useState(false)
  const [timeVisitPage, setTimeVisitPage] = useCookieState('_time_visit', '')
  const [timeVisitPageGlobal, setTimeVisitPageGlobal] = useLocalVisitTime()
  const [showMessage, setShowMessage] = useCookieState<showValueCD>('_show_message_', 'NONE')
  const { notiDispatch } = useNotiContext()

  const { initItems, addItems, updateItems, calcOrder } = useOrderService()
  const discountPercent = sellpageSetting?.settings?.general?.upsell_info?.discount_percent || 0
  const [forcedTab, setForcedFlag] = useState<number>(0)
  const [open, setOpen] = useState(false)
  const versionCookie = useVersionCookie()
  const [cartCookie] = useClearCartCookie()
  const [priceChangedVariantItems, setPriceChangedVariantItems] = usePriceChangedItems()

  const isPreOrder = sellpageSetting?.settings?.general?.pre_order
  const hideV144 = sellpageSetting?.settings?.configurations?.hide_clearance || isPreOrder
  const percentOff = Object.values(variantItems)
    .filter((x) => x.sellable)
    .reduce((acc: number, next: S3Types.page_variants_item) => {
      const percentNext = 100 - ((next.default_price || 0) * 100) / (next.compare_price || 1)
      return acc > 0 ? (acc < percentNext ? acc : percentNext) : percentNext
    }, 0)

  useEffect(() => {
    if (cartCookie == 'true') {
      logger.logProductEvent('reset_version')
      setLocalOrder(null)
      setCookie('is_clear_cart', '', 0)
    }
  }, [cartCookie])

  useEffect(() => {
    if (showMessage === 'POPUP' && !localOrder?.items?.length && !localIsPuchase) {
      logger.logProductEvent('clearance_popup')
      setOpen(true)
      setShowMessage('TEXT')
    }
  }, [showMessage])

  const countDown: number = useMemo(() => {
    const date = new Date()
    if (
      (versionBase144Not146?.includes(versionCookie || '') ||
        versionBase144Not146?.includes(storeSetting?.version || '')) &&
      !hideV144
    ) {
      if (timeVisitPage) {
        const timeSecond = (date.getTime() - +(timeVisitPage || '')) / 1000
        if (timeSecond > (resetCountDown || 21600)) {
          setTimeVisitPage('')
          setShowMessage('NONE')
          return socialProof?.countdown_time || 0
        } else if (timeSecond >= (socialProof?.countdown_time || 0)) {
          if (
            (!showMessage || showMessage === 'NONE') &&
            !localOrder?.items?.length &&
            !localIsPuchase
          )
            setShowMessage('POPUP')
          return 0
        } else {
          return Math.floor((socialProof?.countdown_time || 0) - timeSecond)
        }
      } else {
        setTimeVisitPage(date.getTime())
        return socialProof?.countdown_time || 0
      }
    } else {
      return socialProof?.countdown_time || 0
    }
  }, [socialProof?.countdown_time, timeVisitPage, isPause])

  const countDownV146: number = useMemo(() => {
    const date = new Date()
    if (
      versionCookie == 'v146' ||
      storeSetting?.version == 'v146'
      // !hideV144
    ) {
      if (timeVisitPageGlobal) {
        const timeSecond = (date.getTime() - +(timeVisitPageGlobal || '')) / 1000
        if (timeSecond > (resetCountDown || 21600)) {
          setTimeVisitPageGlobal(0)
          setShowMessage('NONE')
          return socialProof?.countdown_time || 0
        } else if (timeSecond >= (socialProof?.countdown_time || 0)) {
          if (
            (!showMessage || showMessage === 'NONE') &&
            !localOrder?.items?.length &&
            !localIsPuchase
          )
            setShowMessage('POPUP')
          return 0
        } else {
          return Math.floor((socialProof?.countdown_time || 0) - timeSecond)
        }
      } else {
        setTimeVisitPageGlobal(date.getTime())
        return socialProof?.countdown_time || 0
      }
    } else {
      return socialProof?.countdown_time || 0
    }
  }, [socialProof?.countdown_time, timeVisitPageGlobal])

  const percentTmp = useMemo(() => {
    if (!sellpageSetting?.settings?.general?.upsell) return 0
    if (totalItems) {
      let tempOrders = [...(localOrder?.items ?? [])]
      const existedItem = tempOrders?.find((item) => item.variant_id === selectedVariant?.id)

      tempOrders = existedItem
        ? [...tempOrders]?.map((order) => {
            if (order.id === existedItem.id) {
              return { ...order, quantity: (existedItem?.quantity ?? 0) + quantity }
            }
            return order
          })
        : [...tempOrders, { ...newOrderItem(), quantity: quantity }]

      const subTotalTmp = preCalcOrder(calcOrder(tempOrders, true))?.items_amount
      return (
        (((totalItems + quantity) * (selectedVariant?.compare_price || 0) - (subTotalTmp || 0)) *
          100) /
        ((totalItems + quantity) * (selectedVariant?.compare_price || 0))
      )
    } else {
      const quantityTmp = quantity > 1 ? quantity : 2
      const tempOrders = [{ ...newOrderItem(), quantity: quantityTmp }]
      const subTotalTmp = preCalcOrder(calcOrder(tempOrders, true))?.items_amount
      return (
        ((quantityTmp * (selectedVariant?.compare_price || 0) - (subTotalTmp || 0)) * 100) /
        (quantityTmp * (selectedVariant?.compare_price || 0))
      )
    }
  }, [selectedVariant, quantity, totalItems])

  useEffect(() => {
    if (totalItems && sellpageSetting?.settings?.general?.upsell) {
      let tempOrders = [...(localOrder?.items ?? [])]
      const existedItem = tempOrders?.find((item) => item.variant_id === selectedVariant?.id)
      tempOrders = existedItem
        ? [...tempOrders]?.map((order) => {
            if (order.id === existedItem.id) {
              return { ...order, quantity: (existedItem?.quantity ?? 0) + quantity }
            }
            return order
          })
        : [...tempOrders, { ...newOrderItem(), quantity: quantity }]
      calcOrder(tempOrders, true)
    }
  }, [selectedVariant, quantity, totalItems])

  useDeepEffect(() => {
    setTotalItems(countItems(variantItemsData))
  }, [variantItemsData])

  const onPropertyChanged = (prop: S3Types.item_properties) => {
    const selectedProps = selectedVariant?.properties
    const newProps = { ...selectedProps, ...prop }
    getSelectedVariant(newProps, { changeImage: true, redirect: true })
    logger.logProductPage('variant')
  }

  useEffect(() => {
    getInitSelectedVariant()

    //TODO: tracking
    if (!localIsPuchase) {
      trackingViewItem()
    }
    fbpTrackViewContent(selectedVariant?.default_price)
    pinterestTrackViewContent(selectedVariant?.default_price)
    snapchatTrackViewContent(selectedVariant?.default_price)
    ttqTrackViewContent(selectedVariant?.default_price)
    if (!router.query['utm_campaign']?.includes('+')) {
      gtagTrackViewItem(selectedVariant?.default_price)
    }
    // Analytics.track('PRODUCT_VIEW')
  }, [])

  useEventListener({
    type: 'visibilitychange',
    listener: onTabActive,
    options: { passive: true },
    element: typeof document !== 'undefined' ? document : undefined
  })

  useEffect(() => {
    logger.logProductPage('quantity')
  }, [quantity])

  function initVariant(
    variants?: S3Types.page_variants,
    properties?: S3Types.item_properties,
    variantItems?: page_variants_items
  ) {
    if (!variants) return null
    const queryVariant = getQuerySelectedVariant()
    if (queryVariant) {
      const selectedVariant = findVariant(queryVariant.properties, variantItems || {})
      return selectedVariant ?? Object.values(variants.items ?? {})?.[0] ?? null
    } else {
      const thisActive = Object.values(variants.items)?.find((x: page_variants_item) => x?.sellable)
      const defaultId = thisActive?.id ?? ''
      if (defaultId) {
        return variants.items?.[defaultId]
      } else {
        const selectedVariant = findVariant(properties || {}, variantItems || {})
        return selectedVariant ?? Object.values(variants.items ?? {})?.[0] ?? null
      }
    }
  }
  function getInitSelectedVariant() {
    const queryVariant = getQuerySelectedVariant()
    // if (!queryVariant) queryVariant = selectedVariant
    // TODO init selected variant
    // let lastVariant = null
    // if (LocalVariant.variant) {
    //   lastVariant = LocalVariant.variant
    //   setQuantity(lastVariant.quantity)
    // }
    // const initSelectedVariant = lastVariant || queryVariant
    const initSelectedVariant = queryVariant
    if (initSelectedVariant?.properties) {
      const isInitProps = compareProperties(
        initSelectedVariant.properties,
        initProperties(properties)
      )
      if (isInitProps) {
        getSelectedVariant(initSelectedVariant.properties, {
          changeImage: true,
          redirect: false
        })
      } else {
        getSelectedVariant(initSelectedVariant.properties, {
          changeImage: true,
          redirect: false
        })
      }
    }
    // else {
    //   getSelectedVariant(initProperties(properties))
    // }
  }

  function getQuerySelectedVariant() {
    const variantBaseId = router.query.vb?.toString()
    const variantId = Object.values(variantItems).find(
      (variant) => variant?.pbase_variant_id == variantBaseId
    )?.id

    if (!variantId) {
      return null
    }

    return variantItems?.[variantId]
  }

  function getSelectedVariant(
    properties: S3Types.item_properties,
    options?: ISelectVariantOptions
  ) {
    const selectedVariant = findVariant(properties, variantItems)
    if (!selectedVariant) {
      setIsSellable(false)
      setIsVariantNotFound(true)
      redirect('/', { query: { vb: undefined }, routerOptions: { scroll: false } })
      return null
    }
    setIsVariantNotFound(false)
    setIsSellable(selectedVariant.sellable ?? true)
    setSelectedVariant(selectedVariant)
    if (options?.changeImage) {
      let index =
        images?.findIndex((image) =>
          image.includes(selectedVariant?.video || selectedVariant?.logo || '')
        ) ?? 0
      index = index < 0 ? 0 : index
      setSyncedActiveImage(index)
      setSyncedChangedVariantImage(index)
    }
    if (options?.redirect && selectedVariant?.id) {
      redirect('/', {
        query: { vb: selectedVariant.pbase_variant_id },
        routerOptions: { scroll: false }
      })
    }
  }

  const handleCountdownOver = () => {
    if (!localOrder?.items?.length && !localIsPuchase) {
      setShowMessage('POPUP')
    }
  }

  function onTabActive() {
    if (
      (versionBase144Not146?.includes(versionCookie || '') ||
        versionBase144Not146?.includes(storeSetting?.version || '')) &&
      !hideV144
    ) {
      const date = new Date()
      if (document.visibilityState !== 'visible') {
        setForcedFlag(date.getTime())
        setIsPause(true)
      } else {
        const duration = date.getTime() - (forcedTab || date.getTime())
        if (duration < 1000) {
          setTimeout(() => {
            setIsPause(false)
            setTimeVisitPage(+timeVisitPage + 1000)
          }, 1000)
        } else {
          setIsPause(false)
          setTimeVisitPage(+timeVisitPage + duration)
        }
      }
    }
  }

  async function onAddToCart(showCartModal = true) {
    if (!isSellable) {
      return false
    }

    if (isPreview) {
      return notiDispatch({
        payload: {
          content: `You couldn't add to cart in preview mode`,
          type: 'is-warning'
        },
        type: 'REMOVE_ALL_AND_ADD'
      })
    }
    getLogRocket().logRocket?.init() // off logrocket
    setIsAddingToCart(true)
    try {
      //TODO: tracking
      fbpTrackAddToCart(selectedVariant?.default_price, quantity)
      pinterestTrackAddToCart(selectedVariant?.default_price, quantity)
      snapchatTrackAddToCart(selectedVariant?.default_price, quantity)
      ttqTrackAddToCart(selectedVariant?.default_price, quantity)
      gtagTrackAddToCart(selectedVariant, quantity)
      if (localIsPuchase) {
        trackingViewItem()
      }
      trackingAddToCart(selectedVariant, quantity)
      logger.logProductEvent('add_to_cart')

      setTimeout(async () => {
        const existedItem = localOrder?.items?.find(
          (item) => item.variant_id === selectedVariant?.id
        )

        existedItem
          ? updateItems([{ id: existedItem.id, quantity: (existedItem?.quantity ?? 0) + quantity }])
          : addItems([newOrderItem()])
        setIsAddingToCart(false)
        // show cart modal
        setCartModalActive(showCartModal)
      }, 500)
    } catch (error) {
      console.error('Add item error', error)
      notiDispatch({
        payload: {
          content: `Cannot add items to cart`,
          type: 'is-danger'
        },
        type: 'REMOVE_ALL_AND_ADD'
      })
      setLocalOrder(null)
      setIsAddingToCart(false)
    }
  }

  function newOrderItem(): Types.OrderItem {
    return {
      id: genSid(),
      variant_id: selectedVariant?.id,
      properties: selectedVariant?.properties,
      price: selectedVariant?.default_price,
      compare_price: selectedVariant?.compare_price,
      quantity: quantity,
      imageUrl: selectedVariant?.video || selectedVariant?.logo || ''
    }
  }

  async function onCheckout() {
    if (isPreview) {
      return notiDispatch({
        payload: {
          content: `You couldn't checkout in preview mode`,
          type: 'is-warning'
        },
        type: 'REMOVE_ALL_AND_ADD'
      })
    }

    if (!creditCard && !paypalEx) {
      return notiDispatch({
        payload: {
          content: `The merchant account has been reached the limitation of payment processing`,
          type: 'is-danger'
        },
        type: 'REMOVE_ALL_AND_ADD'
      })
    }

    setIsCheckingOut(true)

    try {
      // const order = await OrderSvc.create(orderVariants, shopId, domain)

      // Analytics.track('CHECKOUT', {
      //   extra: { order, checkoutNow: false }
      // })

      logger.logCart('buy')

      const result = await initItems(localOrder?.items ?? [], false, true)

      fbpTrackInitiateCheckout(result?.items, result?.items_amount)
      snapchatTrackInitiateCheckout(result?.items, result?.items_amount)
      ttqTrackInitiateCheckout(result?.items, result?.items_amount)
      // ttqTrackCheckout(result?.items, result?.items_amount)
      gtagTrackBeginCheckout(result?.items, result?.items_amount)

      redirect('/checkout')
    } catch (err: any) {
      console.error('Create order error', err)
      setIsCheckingOut(false)

      const message = 'Checkout error. Please try again later'
      const notifyContent =
        'The merchant account has been reached the limitation of payment processing'

      if (err?.response?.data?.error === message) {
        notiDispatch({
          payload: { content: notifyContent, type: 'is-danger' },
          type: 'REMOVE_ALL_AND_ADD'
        })
        // captureApiException(this, err, 'Disabled checkout', message)
      } else {
        notiDispatch({
          payload: { content: 'Checkout failure!', type: 'is-danger' },
          type: 'REMOVE_ALL_AND_ADD'
        })
        // captureApiException(this, err, 'Checkout failure')
      }
    }
  }

  async function onBuyNow() {
    if (isPreview) {
      return notiDispatch({
        payload: {
          content: `You couldn't checkout in preview mode`,
          type: 'is-warning'
        },
        type: 'REMOVE_ALL_AND_ADD'
      })
    }
    getLogRocket().logRocket?.init()

    if (!isSellable) {
      return notiDispatch({
        payload: {
          content: `You couldn't buy a sold out item`,
          type: 'is-warning'
        },
        type: 'REMOVE_ALL_AND_ADD'
      })
    }

    if (!creditCard && !paypalEx) {
      return notiDispatch({
        payload: {
          content: `The merchant account has been reached the limitation of payment processing`,
          type: 'is-danger'
        },
        type: 'REMOVE_ALL_AND_ADD'
      })
    }

    setIsBuyingNow(true)
    logger.logProductPage('buy')
    // if (
    //   LocalOrder?.nowOrder?.status === OrderStatus.COMPLETED ||
    //   LocalOrder?.order?.status === OrderStatus.CONFIRMED
    // ) {
    //   LocalOrder.resetAll()
    // }
    try {
      if (localIsPuchase) {
        trackingViewItem()
      }
      const result = await initItems([newOrderItem()], true)
      fbpTrackInitiateCheckout(result?.items, result?.amount)
      snapchatTrackInitiateCheckout(result?.items, result?.amount)
      ttqTrackInitiateCheckout(result?.items, result?.amount)
      // ttqTrackCheckout(result?.items, result?.amount)
      gtagTrackBeginCheckout(result?.items, result?.amount)
      fbpTrackAddToCart(selectedVariant?.default_price, quantity)
      pinterestTrackAddToCart(selectedVariant?.default_price, quantity)
      snapchatTrackAddToCart(selectedVariant?.default_price, quantity)
      ttqTrackAddToCart(selectedVariant?.default_price, quantity)
      gtagTrackAddToCart(selectedVariant, quantity)
      trackingAddToCart(selectedVariant, quantity)

      // Analytics.track('CHECKOUT', {
      //   extra: { order, checkoutNow: true }
      // })

      redirect('/checkout')
    } catch (err: any) {
      console.error('Buy now error. Cannot create order', err)
      setIsBuyingNow(false)

      const message = 'Checkout error. Please try again later'
      const notifyContent =
        'The merchant account has been reached the limitation of payment processing'

      if (err?.response?.data?.error === message) {
        notiDispatch({
          payload: { content: notifyContent, type: 'is-danger' },
          type: 'REMOVE_ALL_AND_ADD'
        })
        // captureApiException(this, err, 'Disabled checkout', message)
      } else {
        notiDispatch({
          payload: { content: 'Checkout failure!', type: 'is-danger' },
          type: 'REMOVE_ALL_AND_ADD'
        })
        // captureApiException(this, err, 'Checkout failure')
      }
    }
  }

  /**
   * specific listener to update sellpage variant items price when price changed
   * Tickets: https://crossian.atlassian.net/wiki/spaces/tech/pages/312868959/SF+-+Price+Change
   */
  useEffect(() => {
    if (
      priceChangedVariantItems &&
      !isEmpty(priceChangedVariantItems) &&
      !sellpageSetting?.settings?.general?.upsell
    ) {
      // console.warn(
      //   'Variant price has been changed',
      //   sellpageSetting?.settings.general.id,
      //   Object.keys(priceChangedVariantItems)
      // )
      const slug = router.query.subpath?.toString()
      const path = `pages.${slug ? `${slug}.` : ''}setting.variants.items`
      const items = { ...variantItems }
      Object.entries(priceChangedVariantItems).forEach(([variant_id, item]) => {
        if (items[variant_id])
          items[variant_id] = { ...items[variant_id], default_price: item.default_price }
      })
      if (selectedVariant?.id && priceChangedVariantItems[selectedVariant.id])
        setSelectedVariant({
          ...selectedVariant,
          default_price: priceChangedVariantItems[selectedVariant.id].default_price
        })
      setStore((store: S3Types.store) => {
        const _store = immutable.set(store, path, { ...items })
        return _store
      })
      setPriceChangedVariantItems({})
    }
  }, [priceChangedVariantItems])

  /**
   * specific listener to update local order items price when variant items price changed
   * Tickets: https://crossian.atlassian.net/wiki/spaces/tech/pages/312868959/SF+-+Price+Change
   */
  useEffect(() => {
    if (sellpageSetting) {
      const temp = [...(localOrder?.items ?? [])]
      const variantItems = sellpageSetting.variants?.items ?? {}
      const itemsTmp = temp
        .filter(
          (item) =>
            item.variant_id &&
            !!variantItems[item.variant_id] &&
            variantItems[item.variant_id].sellable
        )
        .map((item) => ({ ...item, price: variantItems[item.variant_id ?? '']?.default_price }))
      let items: SfTypes.Item[] = []
      if (sellpageSetting?.settings?.general?.upsell) {
        items = calcOrder(itemsTmp)
      } else {
        items = itemsTmp
      }
      const newOrder = preCalcOrder(items)
      setLocalOrder((prev) => ({ ...prev, ...newOrder, items }))
    }
  }, [])

  return (
    <>
      <div className="product-form">
        <style jsx global>
          {globalStyle}
        </style>
        <div className="product__title">{sellpageSetting?.settings?.general?.title}</div>

        {isVariantNotFound && <div className="product__price">--</div>}
        {!isVariantNotFound && (
          <>
            <div className="product__price">
              {getCurrency(selectedVariant?.default_price ?? 0)}{' '}
              {sellpageSetting?.settings?.configurations?.show_compared_price &&
                !!selectedVariant?.default_price &&
                !!selectedVariant?.compare_price &&
                selectedVariant?.default_price < selectedVariant?.compare_price &&
                !sellpageSetting?.settings?.configurations.hide_clearance && (
                  <del className="compared">{getCurrency(selectedVariant?.compare_price ?? 0)}</del>
                )}
              {isPreOrder && <p className="preorder">PRE-ORDER</p>}
            </div>
            {isPreOrder && <PreOrderItem />}
          </>
        )}

        {sellpageSetting?.settings?.configurations?.show_countdown &&
          !isPreOrder &&
          socialProof?.countdown_time &&
          hasAvailableVariants && (
            <div className="countdown">
              {versionCookie === 'v14' ||
              versionCookie === 'v141' ||
              versionCookie === 'vx141' ||
              ((versionBase144?.includes(versionCookie || '') ||
                versionBase144?.includes(storeSetting?.version || '')) &&
                (showMessage === 'NONE' || !showMessage) &&
                !hideV144) ? (
                <>
                  {countdownText === 'Last Minute' ||
                  versionCookie === 'a145' ||
                  storeSetting?.version === 'a145' ? (
                    <>
                      <strong>Last Minute&nbsp;</strong>- Sale ends in&nbsp;
                    </>
                  ) : countdownText === 'Christmas Sale' ? (
                    <>
                      <strong className="has-text-link">Christmas Sale&nbsp;</strong>- ends in&nbsp;
                    </>
                  ) : (
                    <>
                      <strong className="has-text-link">
                        {countdownText ? countdownText : 'Clearance'}&nbsp;
                      </strong>
                      - Sale ends in&nbsp;
                    </>
                  )}
                </>
              ) : versionCookie === 'v142' ? (
                <>
                  <strong className="clearance">Clearance</strong>Sale ends in&nbsp;
                </>
              ) : (versionBase144?.includes(versionCookie || '') ||
                  versionBase144?.includes(storeSetting?.version || '')) &&
                showMessage === 'TEXT' &&
                !hideV144 ? (
                <></>
              ) : !sellpageSetting?.settings?.configurations?.hide_clearance ? (
                <>
                  {countdownText === 'Last Minute' ||
                  versionCookie === 'v144' ||
                  storeSetting?.version === 'v144' ? (
                    <>
                      <strong>Last Minute&nbsp;</strong>- Sale ends in&nbsp;
                    </>
                  ) : countdownText === 'Christmas Sale' ? (
                    <>
                      <strong className="has-text-link">Christmas Sale&nbsp;</strong>- ends in&nbsp;
                    </>
                  ) : (
                    <>
                      <strong className="has-text-link">
                        {countdownText ? countdownText : 'Clearance'}&nbsp;
                      </strong>
                      - Sale ends in&nbsp;
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
              {(versionBase144?.includes(versionCookie || '') ||
                versionBase144?.includes(storeSetting?.version || '')) &&
              !hideV144 ? (
                <>
                  {showMessage === 'TEXT' ? (
                    <div className="text-clearance">You&apos;ve got the best clearance deal!</div>
                  ) : (
                    <strong className="has-text-link">
                      {versionCookie === 'v146' || storeSetting?.version == 'v146' ? (
                        <Countdown
                          time={countDownV146}
                          callBack={handleCountdownOver}
                          className="is-inline"
                        />
                      ) : (
                        <Countdown
                          time={countDown}
                          className="is-inline"
                          callBack={handleCountdownOver}
                          isPause={isPause}
                        />
                      )}
                    </strong>
                  )}
                </>
              ) : (
                !sellpageSetting?.settings?.configurations?.hide_clearance && (
                  <strong className="has-text-link">
                    <Countdown time={socialProof?.countdown_time} className="is-inline" />
                  </strong>
                )
              )}
            </div>
          )}

        {shortDescription && (
          <Description
            className="product__short-description rich-text"
            description={shortDescription}
          />
        )}

        {configurations?.variant_display && (
          <>
            <div className="product__options">
              {configurations?.variant_display === 'Button'
                ? properties &&
                  Object.keys(properties).map((property) => {
                    if (properties?.[property].values) {
                      return (
                        <RadioButton
                          classVariant="is-variant"
                          key={`radio_${property}`}
                          propertyName={property}
                          selectedProperty={selectedVariant?.properties?.[property] ?? ''}
                          values={Object.keys(properties?.[property].values ?? {})}
                          onPropertyChanged={(propertyCode) => {
                            onPropertyChanged({ [property]: propertyCode })
                          }}
                          version={storeSetting?.version || versionCookie}
                        />
                      )
                    } else return null
                  })
                : Object.keys(properties).map((property) => {
                    if (properties?.[property].values) {
                      return (
                        <DropdownSelect
                          key={`dropdown_${property}`}
                          propertyName={property}
                          selectedProperty={selectedVariant?.properties?.[property] ?? ''}
                          values={Object.keys(properties?.[property].values ?? {})}
                          onPropertyChanged={(propertyCode) => {
                            onPropertyChanged({ [property]: propertyCode })
                          }}
                        />
                      )
                    } else return null
                  })}
              {}

              {sellpageSetting?.settings?.general?.upsell && !isMaxNegative && isSellable && (
                <div className="product-upsells">
                  <>
                    {totalItems == 0 ? (
                      <>
                        <span className="product-upsells__bold">
                          ADD {quantity > 1 ? quantity : 2} ITEMS TO CART TO GET{' '}
                          {~~(percentTmp || 0)}% OFF{' '}
                        </span>
                      </>
                    ) : (
                      <>
                        <span>
                          <span className="product-upsells__bold">
                            EXTRA{' '}
                            {discountPercent * maxQuantityDiscount +
                              initiateDiscountPercentUpsell || 0}
                            % OFF FOR {quantity > 1 ? `${quantity} ITEMS MORE` : 'NEXT ITEM'} IN
                            CART
                          </span>
                        </span>
                      </>
                    )}
                  </>
                  <span>Apply to any {Object.keys(properties).join(' and ')}</span>
                </div>
              )}
            </div>
            {sellpageSetting?.settings?.configurations?.show_instock && isSellable && (
              <div className="in-stock is-flex is-flex-direction-row mb-3">
                <img src={iconPath('check-single.svg')} alt="check single" className="mr-2" />
                <strong>In stock</strong>
              </div>
            )}
          </>
        )}

        <div className="cta-buttons field is-grouped is-grouped-multiline">
          {hasAvailableVariants && (
            <div className="control">
              <QuantityInput
                className="is-fullwidth-mobile"
                label=""
                valueSyncedProp={getQuantitySyncedProp()}
                isHorizontal={false}
              />
            </div>
          )}
          {configurations?.has_cart && (
            <>
              <div className={`control ${isPreOrder ? 'prodpage-pre-order' : ''}`}>
                <button
                  className={[
                    'add-to-cart-button button--cta button is-primary',
                    ...(isAddingToCart ? ['is-loading'] : [])
                  ].join(' ')}
                  disabled={!isSellable}
                  onClick={() => onAddToCart()}
                >
                  <img src={iconPath('cart.svg')} alt="cart item" />
                  <span className="add-to-cart-button__content">
                    {isPreOrder ? 'Pre-order now' : `${isSellable ? 'Add to cart' : 'Sold out'}`}
                  </span>
                </button>
              </div>
              {isPreOrder && <PreOrderSlotLeft />}
              {!isPreOrder && (
                <div style={{ display: isSellable ? 'block' : 'none' }} className="control">
                  <button
                    className={[
                      'buy-now-button button--cta button is-link',
                      ...(isBuyingNow ? ['is-loading'] : [])
                    ].join(' ')}
                    disabled={!isSellable}
                    onClick={() => onBuyNow()}
                  >
                    <img src={iconPath('card.svg')} alt="card" />
                    Buy it now
                  </button>
                </div>
              )}
            </>
          )}
          {!configurations?.has_cart && !isPreOrder && (
            <>
              <div className="control">
                <button
                  className={`buy-now-button button--cta button is-primary
            ${isBuyingNow ? 'is-loading' : ''}`}
                  disabled={!isSellable}
                  onClick={() => onBuyNow()}
                >
                  <img src={iconPath('card.svg')} alt="card" />
                  <span className="pay-with-card-button__content">
                    {isSellable ? 'Buy it now' : 'Sold out'}
                  </span>
                </button>
              </div>
            </>
          )}
        </div>

        {configurations?.show_accept_payment && (!!paypalEx || !!creditCard) && (
          <div className="accepted-payment mt-4 mb-3">
            <strong>Accepted Payment Methods</strong>
            <div className="payment-methods is-flex mt-2">
              {paypalEx && (
                <figure className="label__icon image">
                  <img src={iconPath('paypal_icon.svg')} alt="paypal" />
                </figure>
              )}
              {creditCard &&
                creditCard.cards.map((card) => (
                  <figure key={card} className="label__icon image">
                    <img src={iconPath(`${card.toLowerCase()}.svg`)} alt="card" />
                  </figure>
                ))}
            </div>
          </div>
        )}

        {sellpageSetting?.settings?.configurations?.show_social_proof &&
          hasAvailableVariants &&
          !isPreOrder && (
            <small className="visting-people">
              <span className="has-text-link">Limited stock!</span>{' '}
              <strong>
                <RandomNumber initValue={socialProof?.visiting_people ?? 0} />
              </strong>{' '}
              people are viewing this and{' '}
              <strong>
                <RandomNumber initValue={socialProof?.purchased_orders ?? 0} type="up" />
              </strong>{' '}
              purchased it.
            </small>
          )}

        {versionCookie == 'p144' || storeSetting?.version == 'p144' ? <Policy /> : ''}

        {configurations?.has_cart && (
          <CartModal
            // percentUpsellNext={percentTmpModal}
            productTitle={sellpageSetting?.settings?.general?.title}
            activeSyncedProp={getCartModalActiveSyncedProp()}
            onCheckout={onCheckout}
            isCheckingOutSyncedProp={getIsCheckingOutSyncedProp()}
          />
        )}
      </div>
      {(versionBase144?.includes(versionCookie || '') ||
        versionBase144?.includes(storeSetting?.version || '')) &&
      percentOff > 0 &&
      !hideV144 ? (
        <CountdownNotifyModal open={open} percentOff={percentOff} countdownText={countdownText} />
      ) : (
        ''
      )}
    </>
  )
}

const globalStyle = css.global`
  .product-form {
    .countdown {
      font-size: 16px;
      margin-bottom: 28px;
    }

    .accepted-payment {
      strong {
        font-size: 13px;
      }

      .label__icon {
        margin-right: 6px;

        img {
          width: unset;
          height: 20px;
        }
      }
    }

    .product-upsells {
      background: #e8f5e9;
      border: 1px dashed #1d9f54;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 8px 6px;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #1d9f54;
      margin-top: 14px;
      font-weight: 500;
      @media screen and (min-width: 768px) {
        font-size: 15px;
      }
      &__bold {
        font-weight: 700;
      }
    }

    .product {
      &__title {
        font-size: 32px;
        line-height: 36px;
        font-weight: bold;
        margin-bottom: 15px;

        @media screen and (max-width: 768px) {
          font-size: 24px;
        }
      }

      &__price {
        font-weight: 500;
        font-size: 24px;
        line-height: 35px;
        margin-bottom: 9px;

        .compared {
          color: #717171;
          font-weight: 300;
          font-size: 20px;
          margin-left: 5px;
        }
      }

      &__short-description {
        margin-bottom: 42px;
      }

      &__options {
        margin-bottom: 49px;

        @media screen and (max-width: 768px) {
          margin-bottom: 20px;
        }
      }
    }

    .in-stock {
      strong {
        font-size: 13px;
      }
    }

    .cta-buttons {
      margin-bottom: -4px;

      &.field.is-grouped {
        & > .control {
          &:not(:last-child) {
            margin-right: 10px;
          }

          & > .button {
            padding: 15px 18px;

            img {
              margin-right: 16px;
              width: 23px;
              height: 23px;
            }
          }
        }
      }

      @media screen and (max-width: 768px) {
        margin-bottom: 8px !important;

        & > .control {
          width: 100%;

          & > .button {
            width: 100%;
          }
        }
      }
    }

    .paypal-button {
      height: 55px;
      min-width: 158px;
    }

    .pay-with-card-button {
      &__content {
        width: 80px;
      }
    }

    .add-to-cart-button {
      &__content {
        width: 90px;
      }
    }
    .clearance {
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;

      letter-spacing: 0.02em;
      text-transform: uppercase;
      padding: 2px 8px;
      margin-right: 8px;
      background: #e53935;
      color: #ffffff;
    }
    .text-clearance {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #1d9f54;
    }
    .preorder {
      display: inline-block;
      vertical-align: middle;
      margin-left: 12px;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      padding: 0 8px;
      color: #ffffff;
      background-color: #ef5350;
      border-radius: 4px;
      letter-spacing: 0.28px;
    }
  }

  .product-form .cta-buttons.field.is-grouped .prodpage-pre-order {
    width: 100%;
    &.control {
      margin-bottom: 9px;
    }

    @media screen and (min-width: 769px) {
      &.control {
        margin-bottom: 12px;
      }
      width: calc(100% - 114px);
      margin-right: 0 !important;
      .add-to-cart-button {
        width: 100%;
      }
    }
  }

  .product-page.vpo1 {
    .product-form {
      .product__title {
        @media screen and (min-width: 769px) {
          margin-top: 7px;
        }
      }
    }
    .column.product-form__section.is-half {
      padding-top: 4px;
    }
    @media screen and (max-width: 768px) {
      & .product-images {
        margin-bottom: 0px;
      }
      & .product-form .product__title {
        margin-bottom: 8px;
      }
      /* .preorder {
        margin-bottom: 8px;
      } */
    }
  }
`
